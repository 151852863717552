.pi-arrow-up-right:before {
    content: "\e9d5";
}
.pi-arrow-down-right:before {
    content: "\e9d6";
}
.pi-align-right:before {
    content: "\e947";
}
.pi-chevron-circle-right:before {
    content: "\e928";
}
.pi-angle-double-right:before {
    content: "\e92d";
}
.pi-angle-right:before {
    content: "\e931";
}
.pi-chevron-right:before {
    content: "\e900";
}
.pi-caret-right:before {
    content: "\e904";
}
.pi-arrow-right:before {
    content: "\e91a";
}
.pi-arrow-circle-right:before {
    content: "\e91f";
}

.pi-arrow-up-left:before {
    content: "\e9d4";
}
.pi-arrow-down-left:before {
    content: "\e9d7";
}
.pi-align-left:before {
    content: "\e946";
}
.pi-chevron-circle-left:before {
    content: "\e92a";
}
.pi-angle-double-left:before {
    content: "\e92e";
}
.pi-angle-left:before {
    content: "\e932";
}
.pi-chevron-left:before {
    content: "\e901";
}
.pi-caret-left:before {
    content: "\e905";
}
.pi-arrow-left:before {
    content: "\e91b";
}
.pi-arrow-circle-left:before {
    content: "\e920";
}
