@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: initial !important;
                margin-right: 0;
            }

            .layout-sidebar {
                transform: translateX(+100%);
                left: initial !important;
                right: 0;
                border-top-left-radius: initial !important;
                border-bottom-left-radius: initial !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: initial !important;
                margin-right: calc($sidebarWidth);
            }

            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    transform: translateX(+100%);
                    left: initial !important;
                    right: 0;
                }

                .layout-main-container {
                    margin-left: initial !important;
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .layout-wrapper {
        .layout-main-container {
            margin-left: initial !important;
            margin-right: 0;
        }

        .layout-sidebar {
            transform: translateX(+100%);
            left: initial !important;
            right: 0;
            border-top-left-radius: initial !important;
            border-bottom-left-radius: initial !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                transform: translateX(0);
            }
        }
    }
}
