.layout-topbar {
  left: initial;
  right: 0;
  transition: right $transitionDuration;
  .layout-topbar-logo {
    img {
      margin-right: initial;
      margin-left: .5rem;
    }
  }
  .layout-menu-button {
    margin-left: initial;
    margin-right: 2rem;
  }
  .layout-topbar-menu {
    margin: 0 auto 0 0;
    .layout-topbar-button {
      margin-left: initial;
      margin-right: 1rem;
      span.p-badge {
        transform: translate(50%, 50%);
      }
    }
  }
}

@media (max-width: 991px) {
  .layout-topbar {
    .layout-menu-button {
      margin-left: initial;
      margin-right: 0;
    }
  }
}