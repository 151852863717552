

  .image-card {

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
    width: 20%;
    height: 190px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

