.site-item {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;

    >img {
        width: 18px;
        margin-right: .5rem;
    }
}

html.rtl {
    .site-item {
        >img {
            margin-right: initial;
            margin-left: .5rem;
        }
    }
}
