.tawreed-input-files-item-upload {
  position: relative;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  padding: .5em;
  aspect-ratio: 1.0;
  .controls {
    width: 100%;
    height: 100%;
    background: var(--surface-100);
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid var(--bluegray-100);
  }
}