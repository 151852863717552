.p-panel.inputfield.inputtable-relation {
  .p-panel-content {
    padding-bottom: 0;
    margin-bottom: 1.25rem;
  }
  &.p-invalid {
    .p-panel-header {
      border-left-color: #e24c4c;
      border-top-color: #e24c4c;
      border-right-color: #e24c4c;
    }
    .p-panel-content {
      border-right-color: #e24c4c;
      border-bottom-color: #e24c4c;
      border-left-color: #e24c4c;
    }
  }
}