

  .bouns_table_plus {
    color: green;
    cursor: pointer;
    &:hover {
        transition: all 0.3 ease-in-out;
        color: rgb(6, 203, 6);
        transform: scale(1.2,1.2);
    }
  }
