.form-tawreed {
  .form-tawreed-header {
    position: relative;
  }
  .p-field,
  .p-field-checkbox,
  .p-field-radiobutton {
    margin-bottom: 2.5rem;
  }
  .p-toolbar {
    margin-bottom: 1rem;
    .p-toolbar-group-left {
      >div {
        >.p-button+.p-button,
        >.p-button+.p-splitbutton {
          margin-left: 10px;
        }
      }
    }
  }
  form {
    .form-tawreed-fields {
      position: relative;
      .form-tawreed-loader {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 6px;
        // since all col-12 has padding .5rem
        left: .5rem;
        top: .5rem;
        right: .5rem;
        bottom: .5rem;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        .form-tawreed-loader-icon {
          font-size: 2rem;
        }
      }
    }
  }
}

html {
  &.rtl {
    .form-tawreed {
      .p-toolbar {
        .p-toolbar-group-left {
          >div {
            >.p-button+.p-button,
            >.p-button+.p-splitbutton {
              margin-left: initial !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}