.p-orderlist-controls{
    display: none !important;
}

.p-orderlist-item.p-highlight{
    color: inherit !important;
    background-color: inherit !important;
}
.hideButth{
    display: none !important;
}
.menuteBttn{
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.p-splitbutton-menubutton{
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
