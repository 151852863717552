.p-timeline.p-timeline-horizontal .p-timeline-event-opposite {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  width: 60px;
  transform: rotate(45deg);
}