body {
    *:not(.pi) {
        font-family: var(--font-family);
    }
}
@font-face {
    font-family: "Cairo";   /*Can be any text*/
    src: local("AirbnbCerealLight"),
      url("../public/assets/fonts/Cairo-VariableFont_slntwght.ttf") format("truetype");
  }

html[lang='ar'] {
    body {
        *:not(.pi) {
            font-family: var(--arabic-font-family);
        }
    }
}

.cart-products {
    .cart-form-products-field {
        .p-inputnumber {
            input {
                width: 100%;
            }
        }
    }
}

.p-panel {
    .p-panel-header {
        .p-panel-title {
            span {
                display: inline-block;
            }

            span + span {
                margin-left: 10px;
            }
        }

        .p-panel-header-buttons {
            .p-button + .p-button {
                margin-left: 10px;
            }
        }
    }
}

.field {
    label {
        font-weight: bold;
    }
}

.p-listbox-item + .p-listbox-item {
    border-top: 5px solid var(--surface-d) !important;
}


.field-tawreed {
    position: relative;

    >.p-autocomplete-panel {
        position: absolute;
        top: 78px !important;
        width: 100%;
        left: 0 !important;
    }
}


.d-ltr {
    direction: ltr !important;
}

.d-rtl {
    direction: rtl !important;
}
