.layout-config {
    right: 0;
    border-top-left-radius: initial !important;
    border-top-right-radius: 12px;
    border-bottom-left-radius: initial !important;
    border-bottom-right-radius: 12px;

    .layout-config-button {
        left: initial !important;
        right: -52px;
        border-radius: 0 $borderRadius $borderRadius 0;
    }

    .layout-config-close {
        right: initial !important;
        left: 1rem;
    }

    .config-scale {
        margin: 1rem 2rem 0 0;

        .p-button {
            margin-right: initial !important;
            margin-left: .5rem;
        }

        i {
            margin-right: initial !important;
            margin-left: .5rem;
        }
    }
}
