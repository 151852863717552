.tawreed-input-files-item {
  position: relative;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  padding: .5em;
  aspect-ratio: 1.0;
  .view {
    .image {
      overflow: hidden;
      border-radius: var(--border-radius);
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      border: 1px solid #ced4da;
    }
    .video {
      background: var(--surface-100);
      overflow: hidden;
      border-radius: var(--border-radius);
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      border: 1px solid #ced4da;
    }
  }
  .controls {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: none !important;
    text-align: center;
    transition: all 2s ease-in-out;
  }
  &:hover {
    .controls {
      display: initial !important;
    }
  }
}