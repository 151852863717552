.arabic-icon {
    background: url("arabic.png") no-repeat !important;
    width:20px !important;
        height:16px !important;
}

.english-icon {
    background: url("english.png") no-repeat !important;
    width:20px !important;
        height:16px !important;
}