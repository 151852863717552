.layout-sidebar {
    left: initial !important;
    right: 0;
    transition: transform $transitionDuration, right $transitionDuration;
}

.layout-menu {
    li {
        a {
            span {
                margin-left: initial !important;
                margin-right: 0.5rem;
            }

            .menuitem-toggle-icon {
                margin-left: initial !important;
                margin-right: auto;
            }
        }

        ul {
            ul {
                padding-left: initial !important;
                padding-right: 1rem;
            }
        }
    }
}
