.tawreed-input-file-item {
  position: relative;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  aspect-ratio: 1.0;
  border: 1px solid var(--bluegray-100);
  border-radius: var(--border-radius);
  .view {
    .image {
      overflow: hidden;
      border-radius: var(--border-radius);
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }
    .video {
      background: var(--surface-100);
      overflow: hidden;
      border-radius: var(--border-radius);
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }
    .other {
      background: var(--surface-100);
      overflow: hidden;
      border-radius: var(--border-radius);
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-overflow: ellipsis;
    }
  }
  .controls {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: none !important;
    text-align: center;
    transition: all 2s ease-in-out;
  }
  &:hover {
    .controls {
      display: initial !important;
    }
  }
  &.empty {
    // overflow: hidden;
    background: var(--surface-100);
  }
}