.p-panel.inputfield.inputupload {
  margin-bottom: 0;
  padding-bottom: 0;
  .p-panel-content {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &.p-invalid {
    .p-panel-header {
      border-left-color: #e24c4c;
      border-top-color: #e24c4c;
      border-right-color: #e24c4c;
    }
    .p-panel-content {
      border-right-color: #e24c4c;
      border-bottom-color: #e24c4c;
      border-left-color: #e24c4c;
    }
  }
}