.p-panel.files-list {
  .p-panel-content {
    padding: 0 0!important;
    .files-list-item {
      padding: 0.625rem 1.25rem;
      +.files-list-item {
        border-top: 1px solid #dee2e6;
      }
      &:hover {
        cursor: pointer;
        color: #495057;
        background-color: #e9ecef;
      }
      .files-list-item-actions {
        .p-button+.p-button {
          margin-left: 10px;
        }
      }
    }
  }
}