.tawreed-table {
  .tawreed-table-toolbar {
    .p-toolbar-group-left {
      h5 {
        margin: 0;
      }
    }
    .p-toolbar-group-left {
      >div {
        >.p-button+.p-button,
        >.p-button+.p-splitbutton {
          margin-left: 10px;
        }
      }
    }
  }
  .p-datatable .p-datatable-tbody>tr>td {
    word-break: break-word;
  }
  .p-datatable .p-selection-column,
  .p-datatable .selection-cell {
    flex: none;
    min-width: 4rem;
  }
  // .p-datatable {
  //   th.p-table-column-amount {
  //     .p-column-title {
  //       text-align: left;
  //       margin-right: auto;
  //       // margin-right: auto;
  //     }
  //     &.p-sortable-column {
  //       .p-column-filter {
  //         margin-left: 0.5rem;
  //       }
  //     }
  //   }
  //   td.p-table-column-amount {
  //     text-align: right;
  //   }
  // }
  .p-checkbox {
    pointer-events: all;
    .p-checkbox-box,
    .p-checkbox-icon {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 960px) {
  .tawreed-table {
    .p-datatable .p-datatable-tbody>tr>td {
      border-width: 0 0 5px 0 !important;
    }
    // .p-datatable .p-datatable-tbody>tr>td {
    //     padding-top: .25rem;
    //     padding-bottom: .25rem;
    // }
  }
}

html {
  &.rtl {
    .tawreed-table {
      .tawreed-table-toolbar {
        .p-toolbar-group-left {
          >div {
            >.p-button+.p-button,
            >.p-button+.p-splitbutton {
              margin-left: initial !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
.p-datatable .p-datatable-thead > tr > th{
  border-bottom: 1px solid !important;
  background: none !important;
}