.splash {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .splash-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      width: 100%;
      max-width: 40%;
    }
    .p-progressbar {
      height: 6px;
      width: 20%;
      max-width: 20%;
      margin: auto auto;
    }
  }
}

html {
  &.rtl {
    .splash {
      left: initial !important;
      right: 0;
    }
  }
}