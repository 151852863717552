.p-link {
  text-align: right;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-radius: 0 6px 6px 0 !important;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-radius: 6px 0 0 6px !important;
}

.p-avatar-group .p-avatar+.p-avatar {
  margin-left: initial;
  margin-right: -1rem;
}

.p-buttonset .p-button:not(:last-child) {
  border-right: 1px solid #ced4da;
  border-left: 0 none;
}

.p-buttonset .p-button:first-of-type {
  border-radius: 0 6px 6px 0;
}

.p-buttonset .p-button:last-of-type {
  border-radius: 6px 0 0 6px;
}

.p-calendar-w-btn-right .p-inputtext {
  border-radius: 0 6px 6px 0;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-radius: 6px 0 0 6px;
}

.p-calendar-w-btn-left .p-inputtext {
  border-radius: 6px 0 0 6px;
}

.p-calendar-w-btn-left .p-datepicker-trigger {
  border-radius: 0 6px 6px 0;
}


/* Keyboard Support */

.p-cascadeselect-group-icon {
  margin-left: initial;
  margin-right: auto;
}

.p-cascadeselect-item-active>.p-cascadeselect-sublist {
  left: initial;
  right: 100%;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
  left: initial;
  right: 8px;
}

.p-colorpicker-panel .p-colorpicker-color-handle {
  left: initial;
  right: 150px;
}

.p-colorpicker-panel .p-colorpicker-hue {
  left: initial;
  right: 167px;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
  left: initial;
  right: 0;
  margin-left: initial;
  margin-right: -2px;
}

.p-confirm-popup {
  left: initial;
  right: 50%;
}

.p-confirm-popup:after,
.p-confirm-popup:before {
  left: initial;
  right: calc(var(--overlayArrowRight, 0) + 1.25rem);
}

.p-confirm-popup:after {
  margin-left: initial;
  margin-right: -8px;
}

.p-confirm-popup:before {
  margin-left: initial;
  margin-right: -10px;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  margin-left: initial;
  margin-right: auto;
}


/* Resizable */

.p-datatable .p-column-resizer {
  right: initial;
  left: 0;
}


/* VirtualScroller */

.p-datatable .p-virtualscroller-loading {
  left: initial;
  right: 0;
}


/* Maximize */

.p-dialog-maximized {
  left: initial !important;
  right: 0 !important;
}


/* Position */

.p-dialog-left {
  justify-content: flex-start;
}

.p-dialog-right {
  justify-content: flex-end;
}

.p-dialog-top {
  align-items: flex-start;
}

.p-dialog-top-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.p-dialog-top-right {
  justify-content: flex-end;
  align-items: flex-start;
}

.p-dialog-bottom {
  align-items: flex-end;
}

.p-dialog-bottom-left {
  justify-content: flex-start;
  align-items: flex-end;
}

.p-dialog-bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
}


/* Resizable */

.p-dialog .p-resizable-handle {
  right: initial;
  left: 1px;
}

.p-divider-horizontal:before {
  left: initial;
  right: 0;
}

.p-divider-horizontal.p-divider-left {
  justify-content: flex-start;
}

.p-divider-horizontal.p-divider-right {
  justify-content: flex-end;
}

.p-divider-vertical:before {
  left: initial;
  right: 50%;
}

.p-divider-vertical.p-divider-top {
  align-items: flex-start;
}

.p-divider-vertical.p-divider-bottom {
  align-items: flex-end;
}


/* Position */


/* top */

.p-dock-top {
  left: initial;
  right: 0;
}


/* bottom */

.p-dock-bottom {
  left: initial;
  right: 0;
}


/* right */

.p-dock-right {
  right: initial;
  left: 0;
}

.p-dock-right.p-dock-magnification .p-dock-item {
  transform-origin: left center;
}


/* left */

.p-dock-left {
  left: initial;
  right: 0;
}

.p-dock-left.p-dock-magnification .p-dock-item {
  transform-origin: right center;
}

.p-fileupload-row>div:last-child {
  text-align: left;
}

.p-fileupload-content>.p-progressbar {
  left: initial;
  right: 0;
}

.p-galleria-item-prev {
  left: initial;
  right: 0;
  border-radius: 6px 0 0 6px;
}

.p-galleria-item-next {
  right: initial;
  left: 0;
  border-radius: 0 6px 6px 0;
}

.p-galleria-caption {
  left: initial;
  right: 0;
}


/* Indicators */

.p-galleria-indicator-onitem.p-galleria-indicators-top .p-galleria-indicators {
  left: initial;
  right: 0;
  align-items: flex-start;
}

.p-galleria-indicator-onitem.p-galleria-indicators-right .p-galleria-indicators {
  right: initial;
  left: 0;
  align-items: flex-end;
}

.p-galleria-indicator-onitem.p-galleria-indicators-bottom .p-galleria-indicators {
  left: initial;
  right: 0;
  align-items: flex-end;
}

.p-galleria-indicator-onitem.p-galleria-indicators-left .p-galleria-indicators {
  left: initial;
  right: 0;
  align-items: flex-start;
}


/* FullScreen */

.p-galleria-mask {
  left: initial;
  right: 0;
}

.p-galleria-close {
  right: initial;
  left: 0;
}

.p-image-preview-indicator {
  left: initial;
  right: 0;
}

.p-image-toolbar {
  right: initial;
  left: 0;
}

.p-inplace-content-close {
  margin-left: initial;
  margin-right: .25rem;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  border-radius: 0 6px 6px 0;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-radius: 6px 0 0 0;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-radius: 0 0 0 6px;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
  border-radius: 6px 0 0 6px;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  border-radius: 0 6px 6px 0;
}


/* Vertical */

.p-megamenu-vertical .p-megamenu-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
  margin-left: initial;
  margin-right: auto;
}

.p-menubar .p-submenu-list>.p-menuitem-active>.p-submenu-list {
  left: initial;
  right: 100%;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
  margin-left: initial;
  margin-right: auto;
}

.p-menubar .p-menubar-custom,
.p-menubar .p-menubar-end {
  margin-left: initial;
  margin-right: auto;
}

.p-message-close.p-link {
  margin-left: initial;
  margin-right: auto;
}

.p-organizationchart-node-content .p-node-toggler {
  margin-left: initial;
  margin-right: -.75rem;
  left: initial;
  right: 50%;
}

.p-paginator-left-content {
  margin-right: initial;
  margin-left: auto;
}

.p-paginator-right-content {
  margin-left: initial;
  margin-right: auto;
}

.p-progressbar-indeterminate .p-progressbar-value::before {
  left: initial;
  right: 0;
}

.p-progressbar-indeterminate .p-progressbar-value::after {
  left: initial;
  right: 0;
}

@-webkit-keyframes p-progressbar-indeterminate-anim {
  0% {
    left: 100%;
    right: -35%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: -90%;
    right: 100%;
  }
}

@keyframes p-progressbar-indeterminate-anim {
  0% {
    left: 100%;
    right: -35%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: -90%;
    right: 100%;
  }
}

@-webkit-keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: 100%;
    right: -200%;
  }
  60% {
    left: -8%;
    right: 107%;
  }
  100% {
    left: -8%;
    right: 107%;
  }
}

@keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: 100%;
    right: -200%;
  }
  60% {
    left: -8%;
    right: 107%;
  }
  100% {
    left: -8%;
    right: 107%;
  }
}

@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.p-scrolltop {
  right: initial;
  left: 20px;
}

.p-scrolltop-sticky.p-link {
  margin-left: initial;
  margin-right: auto;
}

.p-sidebar-mask {
  left: initial;
  right: 0;
}

.p-sidebar-full .p-sidebar {
  left: initial !important;
  right: 0 !important;
}


/* Animation */


/* Top, Bottom, Left and Right */

.p-sidebar-left .p-sidebar-enter,
.p-sidebar-left .p-sidebar-exit-active {
  transform: translate3d(+100%, 0px, 0px);
}

.p-sidebar-right .p-sidebar-enter,
.p-sidebar-right .p-sidebar-exit-active {
  transform: translate3d(-100%, 0px, 0px);
}


/* Full */


/* Position */

.p-sidebar-left {
  justify-content: flex-start;
}

.p-sidebar-right {
  justify-content: flex-end;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  margin-left: initial;
  margin-right: auto;
}

.p-slider-horizontal .p-slider-range {
  left: initial;
  right: 0;
}

.p-slider-vertical .p-slider-handle {
  left: initial;
  right: 50%;
}

.p-slider-vertical .p-slider-range {
  left: initial;
  right: 0;
}

.p-speeddial-mask {
  left: initial;
  right: 0;
}

.p-speeddial-opened .p-speeddial-rotate {
  transform: rotate(-45deg);
}

.p-splitbutton .p-splitbutton-defaultbutton {
  border-radius: 0 6px 6px 0 !important;
  border-right: initial !important;
  border-left: 0 none;
}

.p-splitbutton-menubutton {
  border-radius: 6px 0 0 6px !important;
}

.p-tabview-nav-prev {
  left: initial;
  right: 0;
}

.p-tabview-nav-next {
  right: initial;
  left: 0;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  margin-left: initial;
  margin-right: auto;
}

.p-tieredmenu .p-menuitem-active>.p-submenu-list {
  left: initial;
  right: 100%;
}

.p-tieredmenu .p-menuitem-active>.p-submenu-list-flipped {
  left: initial;
  right: -100%;
}

.p-timeline-left .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-left .p-timeline-event-content {
  text-align: right;
}

.p-timeline-right .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-right .p-timeline-event-content {
  text-align: left;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-content {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-content {
  text-align: left;
}

.p-toast-message-content {
  align-items: flex-start;
}

.p-toast-top-right {
  right: initial;
  left: 20px;
}

.p-toast-top-left {
  left: initial;
  right: 20px;
}

.p-toast-bottom-left {
  left: initial;
  right: 20px;
}

.p-toast-bottom-right {
  right: initial;
  left: 20px;
}

.p-toast-top-center {
  left: initial;
  right: 50%;
  transform: translateX(+50%);
}

.p-toast-bottom-center {
  left: initial;
  right: 50%;
  transform: translateX(+50%);
}

.p-toast-center {
  left: initial;
  right: 50%;
  transform: translate(+50%, -50%);
}

.p-tooltip-right .p-tooltip-arrow {
  left: initial;
  right: 0;
  border-width: .25em 0 .25em .25em;
}

.p-tooltip-left .p-tooltip-arrow {
  right: initial;
  left: 0;
  border-width: .25em .25rem .25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  left: initial;
  right: 50%;
  margin-left: initial;
  margin-right: -.25rem;
}

.p-tooltip-bottom .p-tooltip-arrow {
  left: initial;
  right: 50%;
  margin-left: initial;
  margin-right: -.25rem;
}

.p-treeselect-close {
  margin-left: initial;
  margin-right: auto;
}

.p-treetable .p-column-resizer {
  right: initial;
  left: 0;
}


/* Frozen Columns */

.p-treetable-unfrozen-view {
  left: initial;
  right: 0;
}

.p-virtualscroller-content {
  left: initial;
  right: 0;
}

.p-virtualscroller-spacer {
  left: initial;
  right: 0;
}

.p-virtualscroller-loader {
  left: initial;
  right: 0;
}