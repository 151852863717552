.col-offset-0 {
    margin-left: initial !important;
    margin-right: 0 !important;
}

.col-offset-1 {
    margin-left: initial !important;
    margin-right: 8.3333% !important;
}

.col-offset-2 {
    margin-left: initial !important;
    margin-right: 16.6667% !important;
}

.col-offset-3 {
    margin-left: initial !important;
    margin-right: 25% !important;
}

.col-offset-4 {
    margin-left: initial !important;
    margin-right: 33.3333% !important;
}

.col-offset-5 {
    margin-left: initial !important;
    margin-right: 41.6667% !important;
}

.col-offset-6 {
    margin-left: initial !important;
    margin-right: 50% !important;
}

.col-offset-7 {
    margin-left: initial !important;
    margin-right: 58.3333% !important;
}

.col-offset-8 {
    margin-left: initial !important;
    margin-right: 66.6667% !important;
}

.col-offset-9 {
    margin-left: initial !important;
    margin-right: 75% !important;
}

.col-offset-10 {
    margin-left: initial !important;
    margin-right: 83.3333% !important;
}

.col-offset-11 {
    margin-left: initial !important;
    margin-right: 91.6667% !important;
}

.col-offset-12 {
    margin-left: initial !important;
    margin-right: 100% !important;
}

@media screen and (min-width: 576px) {
    .sm\:col-offset-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }
    .sm\:col-offset-1 {
        margin-left: initial !important;
        margin-right: 8.3333% !important;
    }
    .sm\:col-offset-2 {
        margin-left: initial !important;
        margin-right: 16.6667% !important;
    }
    .sm\:col-offset-3 {
        margin-left: initial !important;
        margin-right: 25% !important;
    }
    .sm\:col-offset-4 {
        margin-left: initial !important;
        margin-right: 33.3333% !important;
    }
    .sm\:col-offset-5 {
        margin-left: initial !important;
        margin-right: 41.6667% !important;
    }
    .sm\:col-offset-6 {
        margin-left: initial !important;
        margin-right: 50% !important;
    }
    .sm\:col-offset-7 {
        margin-left: initial !important;
        margin-right: 58.3333% !important;
    }
    .sm\:col-offset-8 {
        margin-left: initial !important;
        margin-right: 66.6667% !important;
    }
    .sm\:col-offset-9 {
        margin-left: initial !important;
        margin-right: 75% !important;
    }
    .sm\:col-offset-10 {
        margin-left: initial !important;
        margin-right: 83.3333% !important;
    }
    .sm\:col-offset-11 {
        margin-left: initial !important;
        margin-right: 91.6667% !important;
    }
    .sm\:col-offset-12 {
        margin-left: initial !important;
        margin-right: 100% !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:col-offset-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }
    .md\:col-offset-1 {
        margin-left: initial !important;
        margin-right: 8.3333% !important;
    }
    .md\:col-offset-2 {
        margin-left: initial !important;
        margin-right: 16.6667% !important;
    }
    .md\:col-offset-3 {
        margin-left: initial !important;
        margin-right: 25% !important;
    }
    .md\:col-offset-4 {
        margin-left: initial !important;
        margin-right: 33.3333% !important;
    }
    .md\:col-offset-5 {
        margin-left: initial !important;
        margin-right: 41.6667% !important;
    }
    .md\:col-offset-6 {
        margin-left: initial !important;
        margin-right: 50% !important;
    }
    .md\:col-offset-7 {
        margin-left: initial !important;
        margin-right: 58.3333% !important;
    }
    .md\:col-offset-8 {
        margin-left: initial !important;
        margin-right: 66.6667% !important;
    }
    .md\:col-offset-9 {
        margin-left: initial !important;
        margin-right: 75% !important;
    }
    .md\:col-offset-10 {
        margin-left: initial !important;
        margin-right: 83.3333% !important;
    }
    .md\:col-offset-11 {
        margin-left: initial !important;
        margin-right: 91.6667% !important;
    }
    .md\:col-offset-12 {
        margin-left: initial !important;
        margin-right: 100% !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:col-offset-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }
    .lg\:col-offset-1 {
        margin-left: initial !important;
        margin-right: 8.3333% !important;
    }
    .lg\:col-offset-2 {
        margin-left: initial !important;
        margin-right: 16.6667% !important;
    }
    .lg\:col-offset-3 {
        margin-left: initial !important;
        margin-right: 25% !important;
    }
    .lg\:col-offset-4 {
        margin-left: initial !important;
        margin-right: 33.3333% !important;
    }
    .lg\:col-offset-5 {
        margin-left: initial !important;
        margin-right: 41.6667% !important;
    }
    .lg\:col-offset-6 {
        margin-left: initial !important;
        margin-right: 50% !important;
    }
    .lg\:col-offset-7 {
        margin-left: initial !important;
        margin-right: 58.3333% !important;
    }
    .lg\:col-offset-8 {
        margin-left: initial !important;
        margin-right: 66.6667% !important;
    }
    .lg\:col-offset-9 {
        margin-left: initial !important;
        margin-right: 75% !important;
    }
    .lg\:col-offset-10 {
        margin-left: initial !important;
        margin-right: 83.3333% !important;
    }
    .lg\:col-offset-11 {
        margin-left: initial !important;
        margin-right: 91.6667% !important;
    }
    .lg\:col-offset-12 {
        margin-left: initial !important;
        margin-right: 100% !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:col-offset-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }
    .xl\:col-offset-1 {
        margin-left: initial !important;
        margin-right: 8.3333% !important;
    }
    .xl\:col-offset-2 {
        margin-left: initial !important;
        margin-right: 16.6667% !important;
    }
    .xl\:col-offset-3 {
        margin-left: initial !important;
        margin-right: 25% !important;
    }
    .xl\:col-offset-4 {
        margin-left: initial !important;
        margin-right: 33.3333% !important;
    }
    .xl\:col-offset-5 {
        margin-left: initial !important;
        margin-right: 41.6667% !important;
    }
    .xl\:col-offset-6 {
        margin-left: initial !important;
        margin-right: 50% !important;
    }
    .xl\:col-offset-7 {
        margin-left: initial !important;
        margin-right: 58.3333% !important;
    }
    .xl\:col-offset-8 {
        margin-left: initial !important;
        margin-right: 66.6667% !important;
    }
    .xl\:col-offset-9 {
        margin-left: initial !important;
        margin-right: 75% !important;
    }
    .xl\:col-offset-10 {
        margin-left: initial !important;
        margin-right: 83.3333% !important;
    }
    .xl\:col-offset-11 {
        margin-left: initial !important;
        margin-right: 91.6667% !important;
    }
    .xl\:col-offset-12 {
        margin-left: initial !important;
        margin-right: 100% !important;
    }
}


.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
    margin-right: initial;
    margin-left: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
    margin-right: initial;
    margin-left: 0.5rem;
}

.field-checkbox > label,
.field-radiobutton > label {
    margin-left: initial;
    margin-right: 0.5rem;
}

.pr-0 {
    padding-right: initial !important;
    padding-left: 0 !important;
}

.pr-1 {
    padding-right: initial !important;
    padding-left: 0.25rem !important;
}

.pr-2 {
    padding-right: initial !important;
    padding-left: 0.5rem !important;
}

.pr-3 {
    padding-right: initial !important;
    padding-left: 1rem !important;
}

.pr-4 {
    padding-right: initial !important;
    padding-left: 1.5rem !important;
}

.pr-5 {
    padding-right: initial !important;
    padding-left: 2rem !important;
}

.pr-6 {
    padding-right: initial !important;
    padding-left: 3rem !important;
}

.pr-7 {
    padding-right: initial !important;
    padding-left: 4rem !important;
}

.pr-8 {
    padding-right: initial !important;
    padding-left: 5rem !important;
}

.pl-0 {
    padding-left: initial !important;
    padding-right: 0 !important;
}

.pl-1 {
    padding-left: initial !important;
    padding-right: 0.25rem !important;
}

.pl-2 {
    padding-left: initial !important;
    padding-right: 0.5rem !important;
}

.pl-3 {
    padding-left: initial !important;
    padding-right: 1rem !important;
}

.pl-4 {
    padding-left: initial !important;
    padding-right: 1.5rem !important;
}

.pl-5 {
    padding-left: initial !important;
    padding-right: 2rem !important;
}

.pl-6 {
    padding-left: initial !important;
    padding-right: 3rem !important;
}

.pl-7 {
    padding-left: initial !important;
    padding-right: 4rem !important;
}

.pl-8 {
    padding-left: initial !important;
    padding-right: 5rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

.px-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.px-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

.px-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
}

@media screen and (min-width: 576px) {


    .sm\:pr-0 {
        padding-right: initial !important;
        padding-left: 0 !important;
    }

    .sm\:pr-1 {
        padding-right: initial !important;
        padding-left: 0.25rem !important;
    }

    .sm\:pr-2 {
        padding-right: initial !important;
        padding-left: 0.5rem !important;
    }

    .sm\:pr-3 {
        padding-right: initial !important;
        padding-left: 1rem !important;
    }

    .sm\:pr-4 {
        padding-right: initial !important;
        padding-left: 1.5rem !important;
    }

    .sm\:pr-5 {
        padding-right: initial !important;
        padding-left: 2rem !important;
    }

    .sm\:pr-6 {
        padding-right: initial !important;
        padding-left: 3rem !important;
    }

    .sm\:pr-7 {
        padding-right: initial !important;
        padding-left: 4rem !important;
    }

    .sm\:pr-8 {
        padding-right: initial !important;
        padding-left: 5rem !important;
    }

    .sm\:pl-0 {
        padding-left: initial !important;
        padding-right: 0 !important;
    }

    .sm\:pl-1 {
        padding-left: initial !important;
        padding-right: 0.25rem !important;
    }

    .sm\:pl-2 {
        padding-left: initial !important;
        padding-right: 0.5rem !important;
    }

    .sm\:pl-3 {
        padding-left: initial !important;
        padding-right: 1rem !important;
    }

    .sm\:pl-4 {
        padding-left: initial !important;
        padding-right: 1.5rem !important;
    }

    .sm\:pl-5 {
        padding-left: initial !important;
        padding-right: 2rem !important;
    }

    .sm\:pl-6 {
        padding-left: initial !important;
        padding-right: 3rem !important;
    }

    .sm\:pl-7 {
        padding-left: initial !important;
        padding-right: 4rem !important;
    }

    .sm\:pl-8 {
        padding-left: initial !important;
        padding-right: 5rem !important;
    }


    .sm\:px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .sm\:px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .sm\:px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .sm\:px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .sm\:px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .sm\:px-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .sm\:px-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .sm\:px-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .sm\:px-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
}
@media screen and (min-width: 768px) {


    .md\:pr-0 {
        padding-right: initial !important;
        padding-left: 0 !important;
    }

    .md\:pr-1 {
        padding-right: initial !important;
        padding-left: 0.25rem !important;
    }

    .md\:pr-2 {
        padding-right: initial !important;
        padding-left: 0.5rem !important;
    }

    .md\:pr-3 {
        padding-right: initial !important;
        padding-left: 1rem !important;
    }

    .md\:pr-4 {
        padding-right: initial !important;
        padding-left: 1.5rem !important;
    }

    .md\:pr-5 {
        padding-right: initial !important;
        padding-left: 2rem !important;
    }

    .md\:pr-6 {
        padding-right: initial !important;
        padding-left: 3rem !important;
    }

    .md\:pr-7 {
        padding-right: initial !important;
        padding-left: 4rem !important;
    }

    .md\:pr-8 {
        padding-right: initial !important;
        padding-left: 5rem !important;
    }

    .md\:pl-0 {
        padding-left: initial !important;
        padding-right: 0 !important;
    }

    .md\:pl-1 {
        padding-left: initial !important;
        padding-right: 0.25rem !important;
    }

    .md\:pl-2 {
        padding-left: initial !important;
        padding-right: 0.5rem !important;
    }

    .md\:pl-3 {
        padding-left: initial !important;
        padding-right: 1rem !important;
    }

    .md\:pl-4 {
        padding-left: initial !important;
        padding-right: 1.5rem !important;
    }

    .md\:pl-5 {
        padding-left: initial !important;
        padding-right: 2rem !important;
    }

    .md\:pl-6 {
        padding-left: initial !important;
        padding-right: 3rem !important;
    }

    .md\:pl-7 {
        padding-left: initial !important;
        padding-right: 4rem !important;
    }

    .md\:pl-8 {
        padding-left: initial !important;
        padding-right: 5rem !important;
    }


    .md\:px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .md\:px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .md\:px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .md\:px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .md\:px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .md\:px-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .md\:px-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .md\:px-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .md\:px-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
}
@media screen and (min-width: 992px) {


    .lg\:pr-0 {
        padding-right: initial !important;
        padding-left: 0 !important;
    }

    .lg\:pr-1 {
        padding-right: initial !important;
        padding-left: 0.25rem !important;
    }

    .lg\:pr-2 {
        padding-right: initial !important;
        padding-left: 0.5rem !important;
    }

    .lg\:pr-3 {
        padding-right: initial !important;
        padding-left: 1rem !important;
    }

    .lg\:pr-4 {
        padding-right: initial !important;
        padding-left: 1.5rem !important;
    }

    .lg\:pr-5 {
        padding-right: initial !important;
        padding-left: 2rem !important;
    }

    .lg\:pr-6 {
        padding-right: initial !important;
        padding-left: 3rem !important;
    }

    .lg\:pr-7 {
        padding-right: initial !important;
        padding-left: 4rem !important;
    }

    .lg\:pr-8 {
        padding-right: initial !important;
        padding-left: 5rem !important;
    }

    .lg\:pl-0 {
        padding-left: initial !important;
        padding-right: 0 !important;
    }

    .lg\:pl-1 {
        padding-left: initial !important;
        padding-right: 0.25rem !important;
    }

    .lg\:pl-2 {
        padding-left: initial !important;
        padding-right: 0.5rem !important;
    }

    .lg\:pl-3 {
        padding-left: initial !important;
        padding-right: 1rem !important;
    }

    .lg\:pl-4 {
        padding-left: initial !important;
        padding-right: 1.5rem !important;
    }

    .lg\:pl-5 {
        padding-left: initial !important;
        padding-right: 2rem !important;
    }

    .lg\:pl-6 {
        padding-left: initial !important;
        padding-right: 3rem !important;
    }

    .lg\:pl-7 {
        padding-left: initial !important;
        padding-right: 4rem !important;
    }

    .lg\:pl-8 {
        padding-left: initial !important;
        padding-right: 5rem !important;
    }


    .lg\:px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .lg\:px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .lg\:px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .lg\:px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .lg\:px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .lg\:px-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .lg\:px-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .lg\:px-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .lg\:px-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
}
@media screen and (min-width: 1200px) {

    .xl\:pr-0 {
        padding-right: initial !important;
        padding-left: 0 !important;
    }

    .xl\:pr-1 {
        padding-right: initial !important;
        padding-left: 0.25rem !important;
    }

    .xl\:pr-2 {
        padding-right: initial !important;
        padding-left: 0.5rem !important;
    }

    .xl\:pr-3 {
        padding-right: initial !important;
        padding-left: 1rem !important;
    }

    .xl\:pr-4 {
        padding-right: initial !important;
        padding-left: 1.5rem !important;
    }

    .xl\:pr-5 {
        padding-right: initial !important;
        padding-left: 2rem !important;
    }

    .xl\:pr-6 {
        padding-right: initial !important;
        padding-left: 3rem !important;
    }

    .xl\:pr-7 {
        padding-right: initial !important;
        padding-left: 4rem !important;
    }

    .xl\:pr-8 {
        padding-right: initial !important;
        padding-left: 5rem !important;
    }

    .xl\:pl-0 {
        padding-left: initial !important;
        padding-right: 0 !important;
    }

    .xl\:pl-1 {
        padding-left: initial !important;
        padding-right: 0.25rem !important;
    }

    .xl\:pl-2 {
        padding-left: initial !important;
        padding-right: 0.5rem !important;
    }

    .xl\:pl-3 {
        padding-left: initial !important;
        padding-right: 1rem !important;
    }

    .xl\:pl-4 {
        padding-left: initial !important;
        padding-right: 1.5rem !important;
    }

    .xl\:pl-5 {
        padding-left: initial !important;
        padding-right: 2rem !important;
    }

    .xl\:pl-6 {
        padding-left: initial !important;
        padding-right: 3rem !important;
    }

    .xl\:pl-7 {
        padding-left: initial !important;
        padding-right: 4rem !important;
    }

    .xl\:pl-8 {
        padding-left: initial !important;
        padding-right: 5rem !important;
    }

    .xl\:px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .xl\:px-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .xl\:px-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .xl\:px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .xl\:px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .xl\:px-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .xl\:px-6 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .xl\:px-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .xl\:px-8 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
}

.mr-0 {
    margin-right: initial !important;
    margin-left: 0 !important;
}

.mr-1 {
    margin-right: initial !important;
    margin-left: 0.25rem !important;
}

.mr-2 {
    margin-right: initial !important;
    margin-left: 0.5rem !important;
}

.mr-3 {
    margin-right: initial !important;
    margin-left: 1rem !important;
}

.mr-4 {
    margin-right: initial !important;
    margin-left: 1.5rem !important;
}

.mr-5 {
    margin-right: initial !important;
    margin-left: 2rem !important;
}

.mr-6 {
    margin-right: initial !important;
    margin-left: 3rem !important;
}

.mr-7 {
    margin-right: initial !important;
    margin-left: 4rem !important;
}

.mr-8 {
    margin-right: initial !important;
    margin-left: 5rem !important;
}

.-mr-1 {
    margin-right: initial !important;
    margin-left: -0.25rem !important;
}

.-mr-2 {
    margin-right: initial !important;
    margin-left: -0.5rem !important;
}

.-mr-3 {
    margin-right: initial !important;
    margin-left: -1rem !important;
}

.-mr-4 {
    margin-right: initial !important;
    margin-left: -1.5rem !important;
}

.-mr-5 {
    margin-right: initial !important;
    margin-left: -2rem !important;
}

.-mr-6 {
    margin-right: initial !important;
    margin-left: -3rem !important;
}

.-mr-7 {
    margin-right: initial !important;
    margin-left: -4rem !important;
}

.-mr-8 {
    margin-right: initial !important;
    margin-left: -5rem !important;
}

.mr-auto {
    margin-right: initial !important;
    margin-left: auto !important;
}

.ml-0 {
    margin-left: initial !important;
    margin-right: 0 !important;
}

.ml-1 {
    margin-left: initial !important;
    margin-right: 0.25rem !important;
}

.ml-2 {
    margin-left: initial !important;
    margin-right: 0.5rem !important;
}

.ml-3 {
    margin-left: initial !important;
    margin-right: 1rem !important;
}

.ml-4 {
    margin-left: initial !important;
    margin-right: 1.5rem !important;
}

.ml-5 {
    margin-left: initial !important;
    margin-right: 2rem !important;
}

.ml-6 {
    margin-left: initial !important;
    margin-right: 3rem !important;
}

.ml-7 {
    margin-left: initial !important;
    margin-right: 4rem !important;
}

.ml-8 {
    margin-left: initial !important;
    margin-right: 5rem !important;
}

.-ml-1 {
    margin-left: initial !important;
    margin-right: -0.25rem !important;
}

.-ml-2 {
    margin-left: initial !important;
    margin-right: -0.5rem !important;
}

.-ml-3 {
    margin-left: initial !important;
    margin-right: -1rem !important;
}

.-ml-4 {
    margin-left: initial !important;
    margin-right: -1.5rem !important;
}

.-ml-5 {
    margin-left: initial !important;
    margin-right: -2rem !important;
}

.-ml-6 {
    margin-left: initial !important;
    margin-right: -3rem !important;
}

.-ml-7 {
    margin-left: initial !important;
    margin-right: -4rem !important;
}

.-ml-8 {
    margin-left: initial !important;
    margin-right: -5rem !important;
}

.ml-auto {
    margin-left: initial !important;
    margin-right: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
}

.mx-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
}

.mx-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
}

.-mx-1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
}

.-mx-2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
}

.-mx-3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
}

.-mx-4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
}

.-mx-5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
}

.-mx-6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
}

.-mx-7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
}

.-mx-8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

@media screen and (min-width: 576px) {
    .sm\:mr-0 {
        margin-right: initial !important;
        margin-left: 0 !important;
    }

    .sm\:mr-1 {
        margin-right: initial !important;
        margin-left: 0.25rem !important;
    }

    .sm\:mr-2 {
        margin-right: initial !important;
        margin-left: 0.5rem !important;
    }

    .sm\:mr-3 {
        margin-right: initial !important;
        margin-left: 1rem !important;
    }

    .sm\:mr-4 {
        margin-right: initial !important;
        margin-left: 1.5rem !important;
    }

    .sm\:mr-5 {
        margin-right: initial !important;
        margin-left: 2rem !important;
    }

    .sm\:mr-6 {
        margin-right: initial !important;
        margin-left: 3rem !important;
    }

    .sm\:mr-7 {
        margin-right: initial !important;
        margin-left: 4rem !important;
    }

    .sm\:mr-8 {
        margin-right: initial !important;
        margin-left: 5rem !important;
    }

    .sm\:-mr-1 {
        margin-right: initial !important;
        margin-left: -0.25rem !important;
    }

    .sm\:-mr-2 {
        margin-right: initial !important;
        margin-left: -0.5rem !important;
    }

    .sm\:-mr-3 {
        margin-right: initial !important;
        margin-left: -1rem !important;
    }

    .sm\:-mr-4 {
        margin-right: initial !important;
        margin-left: -1.5rem !important;
    }

    .sm\:-mr-5 {
        margin-right: initial !important;
        margin-left: -2rem !important;
    }

    .sm\:-mr-6 {
        margin-right: initial !important;
        margin-left: -3rem !important;
    }

    .sm\:-mr-7 {
        margin-right: initial !important;
        margin-left: -4rem !important;
    }

    .sm\:-mr-8 {
        margin-right: initial !important;
        margin-left: -5rem !important;
    }

    .sm\:mr-auto {
        margin-right: initial !important;
        margin-left: auto !important;
    }

    .sm\:ml-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }

    .sm\:ml-1 {
        margin-left: initial !important;
        margin-right: 0.25rem !important;
    }

    .sm\:ml-2 {
        margin-left: initial !important;
        margin-right: 0.5rem !important;
    }

    .sm\:ml-3 {
        margin-left: initial !important;
        margin-right: 1rem !important;
    }

    .sm\:ml-4 {
        margin-left: initial !important;
        margin-right: 1.5rem !important;
    }

    .sm\:ml-5 {
        margin-left: initial !important;
        margin-right: 2rem !important;
    }

    .sm\:ml-6 {
        margin-left: initial !important;
        margin-right: 3rem !important;
    }

    .sm\:ml-7 {
        margin-left: initial !important;
        margin-right: 4rem !important;
    }

    .sm\:ml-8 {
        margin-left: initial !important;
        margin-right: 5rem !important;
    }

    .sm\:-ml-1 {
        margin-left: initial !important;
        margin-right: -0.25rem !important;
    }

    .sm\:-ml-2 {
        margin-left: initial !important;
        margin-right: -0.5rem !important;
    }

    .sm\:-ml-3 {
        margin-left: initial !important;
        margin-right: -1rem !important;
    }

    .sm\:-ml-4 {
        margin-left: initial !important;
        margin-right: -1.5rem !important;
    }

    .sm\:-ml-5 {
        margin-left: initial !important;
        margin-right: -2rem !important;
    }

    .sm\:-ml-6 {
        margin-left: initial !important;
        margin-right: -3rem !important;
    }

    .sm\:-ml-7 {
        margin-left: initial !important;
        margin-right: -4rem !important;
    }

    .sm\:-ml-8 {
        margin-left: initial !important;
        margin-right: -5rem !important;
    }

    .sm\:ml-auto {
        margin-left: initial !important;
        margin-right: auto !important;
    }

    .sm\:mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .sm\:mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .sm\:mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .sm\:mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .sm\:mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .sm\:mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .sm\:mx-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .sm\:mx-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .sm\:mx-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .sm\:-mx-1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .sm\:-mx-2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .sm\:-mx-3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .sm\:-mx-4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }

    .sm\:-mx-5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }

    .sm\:-mx-6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .sm\:-mx-7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .sm\:-mx-8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .sm\:mx-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:mr-0 {
        margin-right: initial !important;
        margin-left: 0 !important;
    }

    .md\:mr-1 {
        margin-right: initial !important;
        margin-left: 0.25rem !important;
    }

    .md\:mr-2 {
        margin-right: initial !important;
        margin-left: 0.5rem !important;
    }

    .md\:mr-3 {
        margin-right: initial !important;
        margin-left: 1rem !important;
    }

    .md\:mr-4 {
        margin-right: initial !important;
        margin-left: 1.5rem !important;
    }

    .md\:mr-5 {
        margin-right: initial !important;
        margin-left: 2rem !important;
    }

    .md\:mr-6 {
        margin-right: initial !important;
        margin-left: 3rem !important;
    }

    .md\:mr-7 {
        margin-right: initial !important;
        margin-left: 4rem !important;
    }

    .md\:mr-8 {
        margin-right: initial !important;
        margin-left: 5rem !important;
    }

    .md\:-mr-1 {
        margin-right: initial !important;
        margin-left: -0.25rem !important;
    }

    .md\:-mr-2 {
        margin-right: initial !important;
        margin-left: -0.5rem !important;
    }

    .md\:-mr-3 {
        margin-right: initial !important;
        margin-left: -1rem !important;
    }

    .md\:-mr-4 {
        margin-right: initial !important;
        margin-left: -1.5rem !important;
    }

    .md\:-mr-5 {
        margin-right: initial !important;
        margin-left: -2rem !important;
    }

    .md\:-mr-6 {
        margin-right: initial !important;
        margin-left: -3rem !important;
    }

    .md\:-mr-7 {
        margin-right: initial !important;
        margin-left: -4rem !important;
    }

    .md\:-mr-8 {
        margin-right: initial !important;
        margin-left: -5rem !important;
    }

    .md\:mr-auto {
        margin-right: initial !important;
        margin-left: auto !important;
    }

    .md\:ml-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }

    .md\:ml-1 {
        margin-left: initial !important;
        margin-right: 0.25rem !important;
    }

    .md\:ml-2 {
        margin-left: initial !important;
        margin-right: 0.5rem !important;
    }

    .md\:ml-3 {
        margin-left: initial !important;
        margin-right: 1rem !important;
    }

    .md\:ml-4 {
        margin-left: initial !important;
        margin-right: 1.5rem !important;
    }

    .md\:ml-5 {
        margin-left: initial !important;
        margin-right: 2rem !important;
    }

    .md\:ml-6 {
        margin-left: initial !important;
        margin-right: 3rem !important;
    }

    .md\:ml-7 {
        margin-left: initial !important;
        margin-right: 4rem !important;
    }

    .md\:ml-8 {
        margin-left: initial !important;
        margin-right: 5rem !important;
    }

    .md\:-ml-1 {
        margin-left: initial !important;
        margin-right: -0.25rem !important;
    }

    .md\:-ml-2 {
        margin-left: initial !important;
        margin-right: -0.5rem !important;
    }

    .md\:-ml-3 {
        margin-left: initial !important;
        margin-right: -1rem !important;
    }

    .md\:-ml-4 {
        margin-left: initial !important;
        margin-right: -1.5rem !important;
    }

    .md\:-ml-5 {
        margin-left: initial !important;
        margin-right: -2rem !important;
    }

    .md\:-ml-6 {
        margin-left: initial !important;
        margin-right: -3rem !important;
    }

    .md\:-ml-7 {
        margin-left: initial !important;
        margin-right: -4rem !important;
    }

    .md\:-ml-8 {
        margin-left: initial !important;
        margin-right: -5rem !important;
    }

    .md\:ml-auto {
        margin-left: initial !important;
        margin-right: auto !important;
    }

    .md\:mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .md\:mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .md\:mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .md\:mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .md\:mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .md\:mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .md\:mx-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .md\:mx-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .md\:mx-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .md\:-mx-1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .md\:-mx-2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .md\:-mx-3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .md\:-mx-4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }

    .md\:-mx-5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }

    .md\:-mx-6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .md\:-mx-7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .md\:-mx-8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .md\:mx-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:mr-0 {
        margin-right: initial !important;
        margin-left: 0 !important;
    }

    .lg\:mr-1 {
        margin-right: initial !important;
        margin-left: 0.25rem !important;
    }

    .lg\:mr-2 {
        margin-right: initial !important;
        margin-left: 0.5rem !important;
    }

    .lg\:mr-3 {
        margin-right: initial !important;
        margin-left: 1rem !important;
    }

    .lg\:mr-4 {
        margin-right: initial !important;
        margin-left: 1.5rem !important;
    }

    .lg\:mr-5 {
        margin-right: initial !important;
        margin-left: 2rem !important;
    }

    .lg\:mr-6 {
        margin-right: initial !important;
        margin-left: 3rem !important;
    }

    .lg\:mr-7 {
        margin-right: initial !important;
        margin-left: 4rem !important;
    }

    .lg\:mr-8 {
        margin-right: initial !important;
        margin-left: 5rem !important;
    }

    .lg\:-mr-1 {
        margin-right: initial !important;
        margin-left: -0.25rem !important;
    }

    .lg\:-mr-2 {
        margin-right: initial !important;
        margin-left: -0.5rem !important;
    }

    .lg\:-mr-3 {
        margin-right: initial !important;
        margin-left: -1rem !important;
    }

    .lg\:-mr-4 {
        margin-right: initial !important;
        margin-left: -1.5rem !important;
    }

    .lg\:-mr-5 {
        margin-right: initial !important;
        margin-left: -2rem !important;
    }

    .lg\:-mr-6 {
        margin-right: initial !important;
        margin-left: -3rem !important;
    }

    .lg\:-mr-7 {
        margin-right: initial !important;
        margin-left: -4rem !important;
    }

    .lg\:-mr-8 {
        margin-right: initial !important;
        margin-left: -5rem !important;
    }

    .lg\:mr-auto {
        margin-right: initial !important;
        margin-left: auto !important;
    }

    .lg\:ml-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }

    .lg\:ml-1 {
        margin-left: initial !important;
        margin-right: 0.25rem !important;
    }

    .lg\:ml-2 {
        margin-left: initial !important;
        margin-right: 0.5rem !important;
    }

    .lg\:ml-3 {
        margin-left: initial !important;
        margin-right: 1rem !important;
    }

    .lg\:ml-4 {
        margin-left: initial !important;
        margin-right: 1.5rem !important;
    }

    .lg\:ml-5 {
        margin-left: initial !important;
        margin-right: 2rem !important;
    }

    .lg\:ml-6 {
        margin-left: initial !important;
        margin-right: 3rem !important;
    }

    .lg\:ml-7 {
        margin-left: initial !important;
        margin-right: 4rem !important;
    }

    .lg\:ml-8 {
        margin-left: initial !important;
        margin-right: 5rem !important;
    }

    .lg\:-ml-1 {
        margin-left: initial !important;
        margin-right: -0.25rem !important;
    }

    .lg\:-ml-2 {
        margin-left: initial !important;
        margin-right: -0.5rem !important;
    }

    .lg\:-ml-3 {
        margin-left: initial !important;
        margin-right: -1rem !important;
    }

    .lg\:-ml-4 {
        margin-left: initial !important;
        margin-right: -1.5rem !important;
    }

    .lg\:-ml-5 {
        margin-left: initial !important;
        margin-right: -2rem !important;
    }

    .lg\:-ml-6 {
        margin-left: initial !important;
        margin-right: -3rem !important;
    }

    .lg\:-ml-7 {
        margin-left: initial !important;
        margin-right: -4rem !important;
    }

    .lg\:-ml-8 {
        margin-left: initial !important;
        margin-right: -5rem !important;
    }

    .lg\:ml-auto {
        margin-left: initial !important;
        margin-right: auto !important;
    }

    .lg\:mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .lg\:mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .lg\:mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .lg\:mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .lg\:mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .lg\:mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .lg\:mx-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .lg\:mx-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .lg\:mx-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .lg\:-mx-1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .lg\:-mx-2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .lg\:-mx-3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .lg\:-mx-4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }

    .lg\:-mx-5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }

    .lg\:-mx-6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .lg\:-mx-7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .lg\:-mx-8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .lg\:mx-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:mr-0 {
        margin-right: initial !important;
        margin-left: 0 !important;
    }

    .xl\:mr-1 {
        margin-right: initial !important;
        margin-left: 0.25rem !important;
    }

    .xl\:mr-2 {
        margin-right: initial !important;
        margin-left: 0.5rem !important;
    }

    .xl\:mr-3 {
        margin-right: initial !important;
        margin-left: 1rem !important;
    }

    .xl\:mr-4 {
        margin-right: initial !important;
        margin-left: 1.5rem !important;
    }

    .xl\:mr-5 {
        margin-right: initial !important;
        margin-left: 2rem !important;
    }

    .xl\:mr-6 {
        margin-right: initial !important;
        margin-left: 3rem !important;
    }

    .xl\:mr-7 {
        margin-right: initial !important;
        margin-left: 4rem !important;
    }

    .xl\:mr-8 {
        margin-right: initial !important;
        margin-left: 5rem !important;
    }

    .xl\:-mr-1 {
        margin-right: initial !important;
        margin-left: -0.25rem !important;
    }

    .xl\:-mr-2 {
        margin-right: initial !important;
        margin-left: -0.5rem !important;
    }

    .xl\:-mr-3 {
        margin-right: initial !important;
        margin-left: -1rem !important;
    }

    .xl\:-mr-4 {
        margin-right: initial !important;
        margin-left: -1.5rem !important;
    }

    .xl\:-mr-5 {
        margin-right: initial !important;
        margin-left: -2rem !important;
    }

    .xl\:-mr-6 {
        margin-right: initial !important;
        margin-left: -3rem !important;
    }

    .xl\:-mr-7 {
        margin-right: initial !important;
        margin-left: -4rem !important;
    }

    .xl\:-mr-8 {
        margin-right: initial !important;
        margin-left: -5rem !important;
    }

    .xl\:mr-auto {
        margin-right: initial !important;
        margin-left: auto !important;
    }

    .xl\:ml-0 {
        margin-left: initial !important;
        margin-right: 0 !important;
    }

    .xl\:ml-1 {
        margin-left: initial !important;
        margin-right: 0.25rem !important;
    }

    .xl\:ml-2 {
        margin-left: initial !important;
        margin-right: 0.5rem !important;
    }

    .xl\:ml-3 {
        margin-left: initial !important;
        margin-right: 1rem !important;
    }

    .xl\:ml-4 {
        margin-left: initial !important;
        margin-right: 1.5rem !important;
    }

    .xl\:ml-5 {
        margin-left: initial !important;
        margin-right: 2rem !important;
    }

    .xl\:ml-6 {
        margin-left: initial !important;
        margin-right: 3rem !important;
    }

    .xl\:ml-7 {
        margin-left: initial !important;
        margin-right: 4rem !important;
    }

    .xl\:ml-8 {
        margin-left: initial !important;
        margin-right: 5rem !important;
    }

    .xl\:-ml-1 {
        margin-left: initial !important;
        margin-right: -0.25rem !important;
    }

    .xl\:-ml-2 {
        margin-left: initial !important;
        margin-right: -0.5rem !important;
    }

    .xl\:-ml-3 {
        margin-left: initial !important;
        margin-right: -1rem !important;
    }

    .xl\:-ml-4 {
        margin-left: initial !important;
        margin-right: -1.5rem !important;
    }

    .xl\:-ml-5 {
        margin-left: initial !important;
        margin-right: -2rem !important;
    }

    .xl\:-ml-6 {
        margin-left: initial !important;
        margin-right: -3rem !important;
    }

    .xl\:-ml-7 {
        margin-left: initial !important;
        margin-right: -4rem !important;
    }

    .xl\:-ml-8 {
        margin-left: initial !important;
        margin-right: -5rem !important;
    }

    .xl\:ml-auto {
        margin-left: initial !important;
        margin-right: auto !important;
    }

    .xl\:mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .xl\:mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .xl\:mx-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .xl\:mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .xl\:mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .xl\:mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .xl\:mx-6 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .xl\:mx-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .xl\:mx-8 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .xl\:-mx-1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }

    .xl\:-mx-2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }

    .xl\:-mx-3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    .xl\:-mx-4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }

    .xl\:-mx-5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }

    .xl\:-mx-6 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }

    .xl\:-mx-7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }

    .xl\:-mx-8 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }

    .xl\:mx-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.border-right-none {
    border-right-width: initial !important;
    border-left-width: 0 !important;
}

.border-right-1 {
    border-right-width: initial !important;
    border-left-width: 1px !important;
}

.border-right-2 {
    border-right-width: initial !important;
    border-left-width: 2px !important;
}

.border-right-3 {
    border-right-width: initial !important;
    border-left-width: 3px !important;
}

.border-left-none {
    border-left-width: initial !important;
    border-right-width: 0 !important;
}

.border-left-1 {
    border-left-width: initial !important;
    border-right-width: 1px !important;
}

.border-left-2 {
    border-left-width: initial !important;
    border-right-width: 2px !important;
}

.border-left-3 {
    border-left-width: initial !important;
    border-right-width: 3px !important;
}

@media screen and (min-width: 576px) {
    .sm\:border-right-none {
        border-right-width: initial !important;
        border-left-width: 0 !important;
    }
    .sm\:border-right-1 {
        border-right-width: initial !important;
        border-left-width: 1px !important;
    }
    .sm\:border-right-2 {
        border-right-width: initial !important;
        border-left-width: 2px !important;
    }
    .sm\:border-right-3 {
        border-right-width: initial !important;
        border-left-width: 3px !important;
    }
    .sm\:border-left-none {
        border-left-width: initial !important;
        border-right-width: 0 !important;
    }
    .sm\:border-left-1 {
        border-left-width: initial !important;
        border-right-width: 1px !important;
    }
    .sm\:border-left-2 {
        border-left-width: initial !important;
        border-right-width: 2px !important;
    }
    .sm\:border-left-3 {
        border-left-width: initial !important;
        border-right-width: 3px !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:border-right-none {
        border-right-width: initial !important;
        border-left-width: 0 !important;
    }
    .md\:border-right-1 {
        border-right-width: initial !important;
        border-left-width: 1px !important;
    }
    .md\:border-right-2 {
        border-right-width: initial !important;
        border-left-width: 2px !important;
    }
    .md\:border-right-3 {
        border-right-width: initial !important;
        border-left-width: 3px !important;
    }
    .md\:border-left-none {
        border-left-width: initial !important;
        border-right-width: 0 !important;
    }
    .md\:border-left-1 {
        border-left-width: initial !important;
        border-right-width: 1px !important;
    }
    .md\:border-left-2 {
        border-left-width: initial !important;
        border-right-width: 2px !important;
    }
    .md\:border-left-3 {
        border-left-width: initial !important;
        border-right-width: 3px !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:border-right-none {
        border-right-width: initial !important;
        border-left-width: 0 !important;
    }
    .lg\:border-right-1 {
        border-right-width: initial !important;
        border-left-width: 1px !important;
    }
    .lg\:border-right-2 {
        border-right-width: initial !important;
        border-left-width: 2px !important;
    }
    .lg\:border-right-3 {
        border-right-width: initial !important;
        border-left-width: 3px !important;
    }
    .lg\:border-left-none {
        border-left-width: initial !important;
        border-right-width: 0 !important;
    }
    .lg\:border-left-1 {
        border-left-width: initial !important;
        border-right-width: 1px !important;
    }
    .lg\:border-left-2 {
        border-left-width: initial !important;
        border-right-width: 2px !important;
    }
    .lg\:border-left-3 {
        border-left-width: initial !important;
        border-right-width: 3px !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:border-right-none {
        border-right-width: initial !important;
        border-left-width: 0 !important;
    }
    .xl\:border-right-1 {
        border-right-width: initial !important;
        border-left-width: 1px !important;
    }
    .xl\:border-right-2 {
        border-right-width: initial !important;
        border-left-width: 2px !important;
    }
    .xl\:border-right-3 {
        border-right-width: initial !important;
        border-left-width: 3px !important;
    }
    .xl\:border-left-none {
        border-left-width: initial !important;
        border-right-width: 0 !important;
    }
    .xl\:border-left-1 {
        border-left-width: initial !important;
        border-right-width: 1px !important;
    }
    .xl\:border-left-2 {
        border-left-width: initial !important;
        border-right-width: 2px !important;
    }
    .xl\:border-left-3 {
        border-left-width: initial !important;
        border-right-width: 3px !important;
    }
}

.border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

@media screen and (min-width: 576px) {
    .sm\:border-round-left {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
    .sm\:border-round-top {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
    .sm\:border-round-right {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    .sm\:border-round-bottom {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}
@media screen and (min-width: 768px) {
    .md\:border-round-left {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
    .md\:border-round-top {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
    .md\:border-round-right {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    .md\:border-round-bottom {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}
@media screen and (min-width: 992px) {
    .lg\:border-round-left {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
    .lg\:border-round-top {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
    .lg\:border-round-right {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    .lg\:border-round-bottom {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}
@media screen and (min-width: 1200px) {
    .xl\:border-round-left {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
    .xl\:border-round-top {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
    .xl\:border-round-right {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
    .xl\:border-round-bottom {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}

.left-auto {
    left: initial !important;
    right: auto !important;
}

.left-0 {
    left: initial !important;
    right: 0 !important;
}

.left-50 {
    left: initial !important;
    right: 50% !important;
}

.left-100 {
    left: initial !important;
    right: 100% !important;
}

@media screen and (min-width: 576px) {
    .sm\:left-auto {
        left: initial !important;
        right: auto !important;
    }
    .sm\:left-0 {
        left: initial !important;
        right: 0 !important;
    }
    .sm\:left-50 {
        left: initial !important;
        right: 50% !important;
    }
    .sm\:left-100 {
        left: initial !important;
        right: 100% !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:left-auto {
        left: initial !important;
        right: auto !important;
    }
    .md\:left-0 {
        left: initial !important;
        right: 0 !important;
    }
    .md\:left-50 {
        left: initial !important;
        right: 50% !important;
    }
    .md\:left-100 {
        left: initial !important;
        right: 100% !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:left-auto {
        left: initial !important;
        right: auto !important;
    }
    .lg\:left-0 {
        left: initial !important;
        right: 0 !important;
    }
    .lg\:left-50 {
        left: initial !important;
        right: 50% !important;
    }
    .lg\:left-100 {
        left: initial !important;
        right: 100% !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:left-auto {
        left: initial !important;
        right: auto !important;
    }
    .xl\:left-0 {
        left: initial !important;
        right: 0 !important;
    }
    .xl\:left-50 {
        left: initial !important;
        right: 50% !important;
    }
    .xl\:left-100 {
        left: initial !important;
        right: 100% !important;
    }
}
.right-auto {
    right: initial !important;
    left: auto !important;
}

.right-0 {
    right: initial !important;
    left: 0 !important;
}

.right-50 {
    right: initial !important;
    left: 50% !important;
}

.right-100 {
    right: initial !important;
    left: 100% !important;
}

@media screen and (min-width: 576px) {
    .sm\:right-auto {
        right: initial !important;
        left: auto !important;
    }
    .sm\:right-0 {
        right: initial !important;
        left: 0 !important;
    }
    .sm\:right-50 {
        right: initial !important;
        left: 50% !important;
    }
    .sm\:right-100 {
        right: initial !important;
        left: 100% !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:right-auto {
        right: initial !important;
        left: auto !important;
    }
    .md\:right-0 {
        right: initial !important;
        left: 0 !important;
    }
    .md\:right-50 {
        right: initial !important;
        left: 50% !important;
    }
    .md\:right-100 {
        right: initial !important;
        left: 100% !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:right-auto {
        right: initial !important;
        left: auto !important;
    }
    .lg\:right-0 {
        right: initial !important;
        left: 0 !important;
    }
    .lg\:right-50 {
        right: initial !important;
        left: 50% !important;
    }
    .lg\:right-100 {
        right: initial !important;
        left: 100% !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:right-auto {
        right: initial !important;
        left: auto !important;
    }
    .xl\:right-0 {
        right: initial !important;
        left: 0 !important;
    }
    .xl\:right-50 {
        right: initial !important;
        left: 50% !important;
    }
    .xl\:right-100 {
        right: initial !important;
        left: 100% !important;
    }
}

.bg-left {
    background-position: right !important;
}

.bg-left-bottom {
    background-position: right bottom !important;
}

.bg-left-top {
    background-position: right top !important;
}

.bg-right {
    background-position: left !important;
}

.bg-right-bottom {
    background-position: left bottom !important;
}

.bg-right-top {
    background-position: left top !important;
}

@media screen and (min-width: 576px) {
    .sm\:bg-left {
        background-position: right !important;
    }
    .sm\:bg-left-bottom {
        background-position: right bottom !important;
    }
    .sm\:bg-left-top {
        background-position: right top !important;
    }
    .sm\:bg-right {
        background-position: left !important;
    }
    .sm\:bg-right-bottom {
        background-position: left bottom !important;
    }
    .sm\:bg-right-top {
        background-position: left top !important;
    }
}
@media screen and (min-width: 768px) {
    .md\:bg-left {
        background-position: right !important;
    }
    .md\:bg-left-bottom {
        background-position: right bottom !important;
    }
    .md\:bg-left-top {
        background-position: right top !important;
    }
    .md\:bg-right {
        background-position: left !important;
    }
    .md\:bg-right-bottom {
        background-position: left bottom !important;
    }
    .md\:bg-right-top {
        background-position: left top !important;
    }
}
@media screen and (min-width: 992px) {
    .lg\:bg-left {
        background-position: right !important;
    }
    .lg\:bg-left-bottom {
        background-position: right bottom !important;
    }
    .lg\:bg-left-top {
        background-position: right top !important;
    }
    .lg\:bg-right {
        background-position: left !important;
    }
    .lg\:bg-right-bottom {
        background-position: left bottom !important;
    }
    .lg\:bg-right-top {
        background-position: left top !important;
    }
}
@media screen and (min-width: 1200px) {
    .xl\:bg-left {
        background-position: right !important;
    }
    .xl\:bg-left-bottom {
        background-position: right bottom !important;
    }
    .xl\:bg-left-top {
        background-position: right top !important;
    }
    .xl\:bg-right {
        background-position: left !important;
    }
    .xl\:bg-right-bottom {
        background-position: left bottom !important;
    }
    .xl\:bg-right-top {
        background-position: left top !important;
    }
}
