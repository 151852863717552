.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 2rem 2rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  flex: 1 1 auto;
}

.layout-wrapper {
  &.layout-landing-mode-active {
    .layout-main {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  &.layout-topbar-hidden {
    .layout-main-container {
      padding-top: 0;
    }
    .layout-topbar {
      display: none;
    }
  }
}
