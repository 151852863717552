.field-inputswitch {
    label {
        vertical-align: super;
        margin-left: 10px;
    }
}

html {
    &.rtl {
        .field-inputswitch {
            label {
                margin-left: initial !important;
                margin-right: 10px;
            }
        }
    }
}
