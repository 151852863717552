
.save-fixed-rtl{
    position: fixed;
     top: 10%;
     right: 0%;
    border-radius: 20px;
    z-index: 2
}
.bt-search {
padding:0.5rem 0.75rem !important;
background-color: black !important;
color:  white !important;
}
.layout-topbar-logo1{
    border-radius: 20px;
    margin-top: 1rem;
}
.p-inputtext {
    padding: 0.5rem 0.75rem;
  }
  .serach-div{
    display: flex;
    align-items: baseline;
  }
  .table-toolbar{
    padding: 0 !important;
    
  }
  .p-button-non-outlined{
    border: none;
    background-color: transparent;
      color: #3cab3e;
  }
  .p-button{
    padding: 0.5rem 1.25rem;
  }
  .p-datatable .p-datatable-header {
    background: transparent;;
    border: none;
    padding: 0;
}
.p-button-success-datatable{
  color: #22C55E !important;
    background: transparent !important;
    border: none !important;
}

.p-button-danger-datatable{
  color: #b3261e !important;
    background: transparent !important;
    border: none !important;
}
.p-button-secondary-datatable{
    color: #64748B !important;
    background: transparent !important;
    border: none !important;
}

.p-splitbutton-menubutton{
  color: #22C55E !important;
  background: transparent !important;
  border: none !important;
}
// h1 {
//   font-family: 'Roboto', sans-serif;
//   font-size: 32px;
//   font-weight: bold;
//   line-height: 39px; /* 1.2 times font size */
//   letter-spacing: 0px;
// }

// h2{
//   font-family: 'Roboto', sans-serif;
//   font-size: 16px;
//   font-weight: regular;
//   line-height: 22px; /* 1.4 times font size */
//   letter-spacing: 0px;

// }
// h3{
//   font-family: 'Roboto', sans-serif;
//   font-size: 16px;
//   font-weight: regular;
//   line-height: 22px; /* 1.4 times font size */
//   letter-spacing: 0px;

// }
// .p-button-label
// {
//   font-family: 'Roboto', sans-serif;
//   font-size: 16px;
//   font-weight: bold;
//   line-height: 22px; /* 1.4 times font size */
//   letter-spacing: 0px;
// }
// .p-button{
//   margin-left: 10px;
// }

body
{
  font-family: 'Cairo' !important;
  font-size: 1rem !important;
  //font-size: 16px;
  //font-weight: bold;
  //line-height: 22px; /* 1.4 times font size */
  letter-spacing: 0px;
}
body *:not(.pi) {
  font-family: 'Cairo' !important;
  //font-size: 1rem !important;
}
html[dir="ltr"] {
.p-column-filter-menu{
  margin-right: auto;
  margin-left: 0;
}
}