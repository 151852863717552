.title_order_card{
    color:black;
    font-weight: bold;
    margin-top: 5px;
}
.value_order_card{
    color:black;
    margin-top: 5px;

}
