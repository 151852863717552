.p-toolbar.tawreed-actions-container {
  .p-toolbar-group-left {
    >div {
      >.p-button+.p-button {
        margin-left: 10px;
      }
    }
  }
}

html {
  &.rtl {
    .p-toolbar.tawreed-actions-container {
      .p-toolbar-group-left {
        >div {
          >.p-button+.p-button {
            margin-left: initial !important;
            margin-right: 10px;
          }
        }
      }
    }
  }
}