//
// variables
//
@import "./_variables";
//
@import "./ltr/design";
//
html[dir="rtl"] {
  direction: rtl;
  @import "./rtl/design";
}

@import "./_overrides";
@import "./seller";